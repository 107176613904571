<template>
  <div class="tabbed-link">
    <router-link
      v-for="link in links"
      :key="link.text"
      :to="link.url"
      class="link"
    >
      {{ link.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.tabbed-link {
  display: inline-flex;
  align-items: center;
  background: #f5f6fe;
  border-radius: 400px;
  padding: 2px 3px;
}

.link {
  padding: 10px 16px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border: 1px solid #f5f6fe;
}

.link:hover {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 100px;
}

@media screen and (max-width: 720px) {
  .link {
    padding: 10px 12px;
  }
}
</style>

<template>
  <div class="talent" :class="{ 'talent--bordered': bordered }">
    <div class="row">
      <div class="col-md-9">
        <div class="flex ai-center">
          <img :src="icon" alt="" class="talent__avatar" />
          <div class="ml-2 flex-1">
            <div class="talent__title">
              {{ title }}
            </div>

            <div class="talent__tags mt-1">
              <div class="talent__tag mr-1">
                {{ company }}
              </div>
              <div class="talent__tag">
                {{ address }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="text-right talent__date">
          {{ date }}
        </div>
      </div>
    </div>

    <hr class="nice-hr mt-1" />
    <div class="talent__tags mt-1">
      <div v-for="tag in skills" :key="tag" class="talent__tag mr-1">
        {{ tag }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    },
    address: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    skills: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.talent {
  padding: 16px 24px;
  border-radius: 8.8px;
}

.talent--bordered {
  border: 1px solid #2575fc;
}

.talent__title {
  font-style: normal;
  font-weight: 400;
  font-size: 18.4024px;
  line-height: 29px;
  color: #101828;
}

.talent__tags {
  display: flex;
  align-items: center;
}

.talent__tag {
  background: #f5f6fe;
  padding: 5px 8.8px;
  font-weight: 400;
  font-size: 14.3129px;
  line-height: 20px;
  color: #667085;
  border-radius: 6px;
}

.talent__date {
  font-weight: 400;
  font-size: 12.2682px;
  line-height: 18px;
  color: #667085;
}

@media only screen and (max-width: 720px) {
  .talent {
    padding: 12px 12px;
  }
  .talent__avatar {
    max-width: 44px;
  }

  .talent__tag {
    font-size: 12px;
  }
}
</style>

<template>
  <LandingPageNewLayout>
    <section class="white-section">
      <div class="container py-5">
        <div class="row ai-center">
          <div
            class="col-md-6 stagger__in-ease-right--1 order-1 order-md-2 text-right"
          >
            <img src="/img/Untitled-design.png" alt="" />
          </div>
          <div
            class="col-md-6 p-1 p-md-0 stagger__in-ease-left--1 order-2 order-md-1"
          >
            <h2 class="h2 mt-2">
              Tech Recruitment and Software Development made easy
            </h2>
            <p class="p mt-2">
              We are here to assist you in hiring diverse, top talent – remote,
              in-person, worldwide. Save hours of sourcing time and shorten your
              recruitment and outsourcing process.
            </p>
            <Button
              theme="blue"
              class="w-20 m-w-100 mt-4"
              @click="$router.push('/hire-talent')"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </section>
    <section class="gray-section py-5 px-1 px-md-0">
      <div class="container py-5">
        <div class="row ai-center">
          <div
            class="col-md-6 stagger__in-ease-right--1 order-1 order-md-2 text-right"
          >
            <img src="/img/Rectangle-61.png" alt="" />
          </div>
          <div
            class="col-md-6 p-1 p-md-0 stagger__in-ease-left--1 order-2 order-md-1"
          >
            <h2 class="h2 mt-2">About Us</h2>
            <h4 class="h4 mt-2">
              We are your innovative Tech Recruitment and IT Outsourcing
              solution
            </h4>
            <p class="p mt-1">
              Infohob is Nigeria’s plug-and-play tech recruitment and IT
              outsourcing solution that helps you design your future workforce.
            </p>
            <Button
              theme="blue"
              class="w-20 m-w-100 mt-4"
              @click="$router.push('/hire-talent')"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </section>
    <section class="white-section py-7 px-1 px-md-0">
      <div class="container">
        <h2 class="text-center h2 mt-2">Our Services</h2>
        <div class="flex jc-center mt-8">
          <TheCard
            v-for="service in services"
            :key="service.title"
            :icon="service.icon"
            :title="service.title"
            :description="service.description"
          >
          </TheCard>
        </div>
        <br />
        <br />

        <h2 class="text-center h2 mt-10">Trusted by incredible clients</h2>

        <div class="row gap-7 mt-8 mb-3">
          <div class="col-md-3" v-for="client in clients" :key="client.icon">
            <div class="text-left">
              <img :src="client.icon" alt="" />
            </div>
            <p class="font-20 mt-1">
              {{ client.description }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="gray-section pt-5 pb-5 px-2 px-md-0">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2 class="h2">
              We simplify Tech Recruitment <br />
              and software development
            </h2>

            <p class="p font-20 mt-2">
              Interested in learning more about Infohob? <br />
              Schedule a call today!
            </p>

            <div class="d-flex ai-center mt-4">
              <img src="/img/icons/phone.png" alt="" />
              <div class="ml-2">
                <strong class="font-22"> Phone Number </strong>
                <p class="font-20 mt-1">+234 806 811 1570</p>
              </div>
            </div>

            <div class="d-flex ai-center mt-4">
              <img src="/img/icons/email.png" alt="" />
              <div class="ml-2">
                <strong class="font-22"> Email Address </strong>
                <p class="font-20 mt-1">hello@infohob.com</p>
              </div>
            </div>

            <p class="font-20 mt-3">Select date for booking or a meeting</p>
            <datepicker :inline="true" class="mt-2"></datepicker>
          </div>

          <div class="col-md-6 pt-10">
            <br />
            <br />
            <Input
              type="text"
              class="col-12 mb-2"
              label-color="#101828"
              border-color="#eaecf0"
              placeholder="Your Name"
              v-model="formData.name"
              name="Name"
            />
            <Input
              type="email"
              class="col-12 mb-2"
              label-color="#101828"
              border-color="#eaecf0"
              placeholder="Your Email"
              v-model="formData.email"
              name="Email"
            />
            <div class="mb-2">
              <VueTelInput
                class="tel__input"
                autoFormat
                defaultCountry="NG"
                v-model="formData.mobile_number"
                :inputOptions="{ placeholder: 'Your Phone' }"
              />
            </div>
            <div class="mb-2">
              <p class="font-20 mb-1">What would you like to talk about?</p>
              <NewRadio
                name="whatabout"
                id="wh1"
                value="Recruitment Services"
                @change=""
                label="Recruitment Services"
                :checked="true"
              />

              <NewRadio
                class="mt-1"
                name="whatabout"
                id="wh2"
                value="Outsourcing Services"
                @change=""
                label="Outsourcing Services"
              />

              <textarea
                style="border-color: #eaecf0"
                placeholder="Message"
                class="mt-2 p-2 h-12"
              />
            </div>

            <div class="d-flex jc-end">
              <Button theme="blue" :style="{ width: '185px' }">Send</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LandingPageNewLayout>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import LandingPageNewLayout from "@/layouts/LandingPageNew.vue";
import TabbedLink from "../components/TabbedLink.vue";
import TheTalent from "../components/TheTalent.vue";
import TheCard from "../components/cards/TheCard.vue";
import DarkSection from "./sections/DarkSection.vue";

export default {
  name: "LandingPage",
  components: {
    LandingPageNewLayout,
    TabbedLink,
    TheTalent,
    DarkSection,
    TheCard,
    Datepicker
  },
  data() {
    return {
      formData: {},
      activeSlide: 1,
      slidesLength: 2,
      tabbedLinks: [
        {
          text: "Hire Top Talent",
          url: "/hire-talent"
        },
        {
          text: "Find Your dream Job!",
          url: "/find-job"
        }
      ],
      talentAvatars: [
        "/img/avatars/Ellipse-24.png",
        "/img/avatars/Ellipse-26.png",
        "/img/avatars/Ellipse-25.png",
        "/img/avatars/Ellipse-27.png",
        "/img/avatars/Ellipse-28.png",
        "/img/avatars/Ellipse-29.png"
      ],
      techStacks: ["Blockchain", "Data science", "Web 3"],
      services: [
        {
          icon: "/img/icons/recruitment-1.svg",
          title: "Tech Recruitment",
          description:
            "Find top tech talent and build a great team from our pool of qualified candidates"
        },
        {
          icon: "/img/icons/software-development-1.svg",
          title: "Software Development",
          description:
            "We build high quality websites, mobile apps and software"
        }
      ],
      clients: [
        {
          icon: "/img/icons/IHS_Towers.png",
          description: "Listed on the New York Stock Exchange (NYSE)"
        },
        {
          icon: "/img/icons/Layer3-1.png",
          description:
            "Recognised by the London Stock Exchange Group as a company to inspire Africa."
        },
        {
          icon: "/img/icons/ITF-1.png",
          description: "Nigeria’s regulatory agency for student internships"
        },
        {
          icon: "/img/icons/Suplias-1.png",
          description:
            "Funded by YCombinator, alongside top companies like AirBnB, Stripe and PayStack."
        }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.tech-stack {
  padding: 12px 32px;
  background: #f9fafb;
  border: 1px solid #eceff3;
  border-radius: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #667085;
}

.tech-stack:first-child {
  border: 1px solid #7e23e0;
  background-color: #fff;
}

@media only screen and (max-width: 720px) {
  .avatar-image {
    max-width: 70px;
  }

  .tech-stack {
    padding: 6px 16px;
    font-size: 14px;
  }
}

strong {
  font-weight: 600;
}
</style>
